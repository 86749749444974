/* eslint-disable max-len */

import React from 'react';
import cx from 'classnames';

import loaderStyles from './today.module.scss';

const TodayLoader = (classNames, styles, callback, fill = 'white') => (
  <svg
    className={cx(loaderStyles.todayLoader, classNames)}
    styles={styles}
    data-test="loader--today"
    data-testid="loader--today"
    width="164"
    height="83"
    viewBox="0 0 164 83"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M82.0035 55.663C68.6732 55.663 57.5706 65.1976 55.1603 77.8202C54.842 79.4938 54.6711 81.2263 54.6711 83H109.336C109.336 81.6682 109.242 80.3718 109.059 79.1107C107.173 65.8518 95.7816 55.663 82.0035 55.663Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M82.0039 28.3276C53.0215 28.3276 29.3135 50.8797 27.4571 79.3894C27.3687 80.5739 27.3393 81.776 27.3393 82.99H48.5958C48.5958 81.6876 48.6724 80.3912 48.8197 79.1242C50.7409 62.5299 64.8962 49.5832 82.0039 49.5832C99.206 49.5832 113.414 62.6536 115.218 79.3776C115.347 80.568 115.412 81.7642 115.412 82.99H136.669C136.669 81.8173 136.633 80.6741 136.563 79.5191C134.771 50.9445 111.028 28.3276 82.0039 28.3276Z" fill={fill} />
    <path onAnimationEnd={callback} fillRule="evenodd" clipRule="evenodd" d="M163.929 79.6314C162.173 35.9062 126.166 0.996826 82.0029 0.996826C38.1463 0.996826 2.32189 35.4348 0.11197 78.7416C0.0353588 80.1559 0 81.5702 0 82.9903H21.2624C21.2624 81.6173 21.3096 80.2384 21.4039 78.883C23.5254 47.2972 49.8913 22.2583 82.0029 22.2583C114.232 22.2583 140.681 47.4916 142.626 79.2425C142.702 80.4859 142.743 81.7293 142.743 82.9903H164C164 81.8707 163.982 80.7393 163.929 79.6314Z" fill={fill} />
  </svg>
);

export default TodayLoader;

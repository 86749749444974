import { useGeoLocation } from 'lib/Hooks/useGeoLocation';
import { useFeatureFlagContext } from 'lib/ContextTypes';

/**
 * @returns {boolean} Indicating whether to disable ads for the user
 */
export const useShouldRestrictVideoAds = () => {
  const { block_ads_in_these_regions: regionsWhereAdsAreProhibited = '' } = useFeatureFlagContext();
  const { geoLocation } = useGeoLocation();
  return regionsWhereAdsAreProhibited.replace(/\s/g, '').split(',').includes(geoLocation);
};

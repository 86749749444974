
import React from 'react';
import cx from 'classnames';

import loaderStyles from './default.module.scss';

const DefaultLoader = (classNames, styles, callback) => (
  <div
    className={cx(loaderStyles.defaultLoader, classNames)}
    styles={styles}
    data-testid="loader--default"
  >
    <div className={loaderStyles.dot} />
    <div className={loaderStyles.dot} />
    <div className={loaderStyles.dot} onAnimationEnd={callback} />
  </div>
);

export default DefaultLoader;

/* eslint-disable max-len */

import React from 'react';
import cx from 'classnames';

import loaderStyles from './news.module.scss';

const NewsLoader = (classNames, styles, callback, fill = 'white') => (
  <svg
    className={cx(loaderStyles.newsLoader, classNames)}
    styles={styles}
    data-testid="loader--news"
    width="162"
    height="91"
    viewBox="0 0 162 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M0.807213 67.6789C5.08388 56.5119 16.9246 52.5681 27.4491 59.3041L73 91H17.7475C6.23541 91 -2.80887 80.4904 0.807213 67.6789Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M46.5176 31.8092L70 85L21.5614 52.1423C9.40856 44.1723 11.8727 30.5088 18.6046 24.4893C28.128 16.5185 41.591 19.2846 46.5176 31.8092Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M79.7701 20.6769L71.0736 80L46.3267 23.5939C40.9754 9.49163 53.0155 0.254066 61.0411 0.0908889C70.2377 -0.880132 81.7769 5.92586 79.7701 20.6769Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M115.709 23.5162L91.1892 80L83.352 29.0198C82.6845 21.2528 87.6879 21.0898 89.5233 19.6331C89.1883 18.4998 86.6862 18.4998 86.6862 18.4998H82.017C81.5165 5.22839 92.1908 -0.597858 100.865 0.048254C110.205 0.373317 120.711 10.8925 115.709 23.5162Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M140.837 52.3353L92 85L115.009 33.0272C121.965 16.6111 138.188 19.8317 143.982 26.1063C150.273 32.2213 152.095 44.61 140.837 52.3353Z" fill={fill} />
    <path onAnimationEnd={callback} fillRule="evenodd" clipRule="evenodd" d="M145.475 91H89L134.876 60.0658C146.14 52.851 158.062 59.4219 161.21 68.8817C164.522 79.4599 157.068 91 145.475 91Z" fill={fill} />
  </svg>
);

export default NewsLoader;
